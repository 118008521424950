import { getCognigyChatStyling } from 'api/contentful-api';
import { useEffect, useState } from 'react';
import { ACCESS_TOKEN } from 'shared/constants/cookies';
import { cookies } from 'shared/utils/storage/cookies';
import { CIAMToken } from 'utils/auth';
import { getParsedToken } from 'utils/misc/getParsedToken';
import { isMobileApp, isNetcrackerApp } from 'utils/user-agent';

declare global {
  interface Window {
    cognigyWebchat: any;
    initWebchat: any;
    initWebchatData: boolean;
  }
}

const COGNIGYSCRIPT = 'https://github.com/Cognigy/Webchat/releases/download/v3.1.0/webchat.js';
const COGNIGY_URL =
  process.env.NODE_ENV === 'production'
    ? 'https://webchat-yousee.cognigy.cloud/v3/e360170a4dd67fee63a97b2ea858f5bc127edfae0c003585122201f7568de847'
    : 'https://endpoint-yousee.cognigy.cloud/bdc838e384e944b5794740dba84ae7aad3f495366f9f5f106e57403ff40f3e01';

const initPayload = (userId: string | undefined, token: CIAMToken | undefined) => {
  window.initWebchatData = true;
  return {
    customer: {
      customerId: userId,
    },
    accessToken: token,
    datetime: new Date().toISOString(),
    currentUrl: window.location.href,
    platform: 'web',
    trigger: {
      type: 'Selfcare',
      payload: {},
    },
  };
};

export const useCognigyScript = () => {
  const [isScriptLoaded, setIsScriptLoaded] = useState(false);
  const [isChatInitialized, setIsChatInitialized] = useState<boolean>(false);

  useEffect(() => {
    if (isNetcrackerApp || isMobileApp) {
      return;
    }

    const script = document.querySelector(`script[src='${COGNIGYSCRIPT}']`) as HTMLElement;
    if (!script) {
      const script = document.createElement('script');
      script.src = COGNIGYSCRIPT;
      script.async = true;
      document.head.appendChild(script);
      script.addEventListener('load', () => setIsScriptLoaded(true));
    }
    const fetchStyleUrl = async () => {
      const cognigyCss = await getCognigyChatStyling();
      const link = document.querySelector(`link[href='${cognigyCss}']`);
      if (!link) {
        const cognigyCssTag = document.createElement('link');
        cognigyCssTag.rel = 'stylesheet';
        cognigyCssTag.type = 'text/css';
        cognigyCssTag.href = cognigyCss;
        document.head.appendChild(cognigyCssTag);
      }
    };
    fetchStyleUrl();
  }, []);

  const initializeCognigyChat = () => {
    const accessToken = cookies.get(ACCESS_TOKEN, true);
    const parsedToken = getParsedToken();
    const userId = parsedToken?.ciam_id;
    if (!isChatInitialized) {
      if (window && window.cognigyWebchat === undefined) {
        window
          .initWebchat(COGNIGY_URL, {
            userId: userId,
          })
          .then((webchat: any) => {
            window.cognigyWebchat = webchat;
            webchat.registerAnalyticsService((event: { type: string }) => {
              if (event.type === 'webchat/open' && !window.initWebchatData) {
                window.cognigyWebchat.sendMessage('', initPayload(userId, accessToken));
              }
            });
            setIsChatInitialized(true);
          });
      }
    }
  };

  if (isScriptLoaded) {
    initializeCognigyChat();
  }
};
